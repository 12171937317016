import React, { Component }from 'react';
import ReactPDF, { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

import Cookies from 'universal-cookie';

import './saveButton.css';
const cookies = new Cookies();


class SaveButton extends Component {


    saveAsPDF() {
        // Create styles
        const styles = StyleSheet.create({
            page: {
            flexDirection: 'row',
            backgroundColor: '#E4E4E4'
            },
            section: {
            margin: 10,
            padding: 10,
            flexGrow: 1
            }
        });
        
        // Create Document Component
        const MyDocument = () => (
            <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                <Text>Section #1</Text>
                </View>
                <View style={styles.section}>
                <Text>Section #2</Text>
                </View>
            </Page>
            </Document>
        );
        ReactPDF.render(<MyDocument />, `${__dirname}/example.pdf`);
        console.log(__dirname)
    }

    render() {
        return (
        <div className='save-button' onClick={this.saveAsPDF.bind(this)}>Save as pdf</div>
        );
    }
}

export default SaveButton;
