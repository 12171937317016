import React, { Component } from 'react';
import Cookies from 'universal-cookie';

import './trainUnitList.css';
const cookies = new Cookies();

class TrainUnitList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            train_units: [],
            timeSum: 0,
        }

    }
    
    componentDidMount() {
        this.createTraining();
        /*if(!cookies.get(this.props.cookieName)){
            this.createTraining();
        } else {
            if(new Date().getTime() > new Date(cookies.get(this.props.cookieName).expires)) {
                this.createTraining();
            } else {
                var cookie = cookies.get(this.props.cookieName);
                this.setState({train_units: cookie.train_units, timeSum: cookie.timeSum});
            }
        }*/
    }

    createTraining() {
        /*var train_unit_list_local = [];
        var alreadyUsedIndexes = [];
        var timeSum = 0;
        do {
            var random = Math.floor(Math.random() * parseInt(this.props.trainUnits.length));
            if(!alreadyUsedIndexes.includes(random)) {
                train_unit_list_local.push(this.props.trainUnits[random]);
                alreadyUsedIndexes.push(random);
                timeSum += this.props.trainUnits[random].duration;
            }
            if(timeSum > this.props.maxDuration) {
                var removed_train_unit = train_unit_list_local.pop();
                timeSum -= removed_train_unit.duration;
                break;
            }
        } while(timeSum < this.props.maxDuration);*/
        this.setState({
            train_units: this.props.trainUnits, //train_unit_list_local,
            timeSum: 0,
        })
        /*var d = new Date();
        cookies.set(this.props.cookieName, { train_units: train_unit_list_local, timeSum, expires: `${d.getFullYear()}-${d.getMonth()+1}-${d.getDate()+1}T${d.getHours()}:${d.getMinutes()}` });*/
    }

    replaceTrainUnit(old_train_unit) {
        var index = this.state.train_units.indexOf(old_train_unit);
        if (index > -1) {
            this.state.train_units.splice(index, 1);
        }
        var timeSum = this.state.timeSum - old_train_unit.duration;
        var train_unit_list_local = [];
        var alreadyUsedIndexes = [];
        do {
            var random = Math.floor(Math.random() * parseInt(this.props.trainUnits.length));
            train_unit_list_local.push(this.props.trainUnits[random]);
            alreadyUsedIndexes.push(random);
            timeSum += this.props.trainUnits[random].duration;
            if(timeSum > this.props.maxDuration) {
                var removed_train_unit = train_unit_list_local.pop();
                timeSum -= removed_train_unit.duration;
                break;
            }
        } while(timeSum < this.props.maxDuration && !train_unit_list_local.includes(old_train_unit));
        var train_units = this.state.train_units;
        train_units.push(...train_unit_list_local);
        this.setState({ train_units, timeSum });
        var d = new Date();
        cookies.set(this.props.cookieName, { train_units: train_units, timeSum, expires: `${d.getFullYear()}-${d.getMonth()+1}-${d.getDate()+1}T${d.getHours()}:${d.getMinutes()}` });
    }

    render() {
        return(
            <div className="train_unit_list">
                <p className="warm_up_title">{this.props.title} {/*<label className="time_title"> - {this.state.timeSum} min</label>*/}</p>
                {
                    this.state.train_units.map((train_unit, i) => {
                        return(
                            <details className='train_unit' key={i}>
                                <summary>
                                    {/*<img src='refresh.png' alt='' onClick={this.replaceTrainUnit.bind(this, train_unit)} />*/}
                                    <p className='title'><b>{train_unit.name}</b></p>
                                    {/*<p className='duration'> - {train_unit.duration} min</p>*/}
                                </summary>
                                <p className='description'>{train_unit.description}</p>
                                {
                                    train_unit.href ? <br /> : ''
                                }
                                {
                                    train_unit.href ? 'Weiter infos: ' : ''
                                }
                                {
                                    train_unit.href ? 
                                    <a href={train_unit.href}>{train_unit.href}</a> : ''
                                }
                            </details>
                        )
                    })
                }
            </div>
        )
    }
}

export default TrainUnitList;