const main_training = [
    {
        name: 'Brennball',
        description: 'Ein Spieler (des Läuferteams) wirft den Ball ins Spielfeld und rennt los. Er muss von einer Matte zur nächsten laufen (vorgegebene Reihenfolge). Das Team im Feld versucht den Ball so schnell wie möglich einzufangen (direktes Fangen aus der Luft, ergibt einen Punkt) und ihn im Brennmal (z.B. Reif) abzulegen. Befindet sich der Läufer in diesem Moment nicht auf einer Matte so "verbrennt" er und muss zurück zum Start. Befindet er sich auf einer Matte, kann er nach dem Wurf des nächsten Läufers die nächste Matte anlaufen. Wenn die Zielmatte erreicht wird, erhält das Team einen Punkt. Kann mit einem Wurf alle vier Matten angelaufen werden, so erhält das Team zwei Punkte. Die Rollen der Mannschaften werden nach einer vorgegebenen Zeit getauscht. Gewonnen hat das Team mit den meisten Punkten (Fangpunkte plus Laufpunkte).',
        href: 'https://www.sportunterricht.ch/lektion/spielen/brennball.php',
        duration: 25
    },
    {
        name: '3er Brennball',
        description: 'Beim 3er Brennball dürfen immer drei Läufer gleichzeitig starten. Jeder von ihnen wirft einen Ball ins Feld und rennt dann los. Die Spieler im Feld müssen den Basketball in den Korb werfen, den Handball ins (Unihockey) Floorballtor werfen und den Fussball ins (Unihockey)Floorballtor schiessen. Die Läufer können so lange laufen, bis alle drei Bälle versenkt sind.',
        href: 'https://www.sportunterricht.ch/lektion/spielen/spielen128.php',
        duration: 25
    },
    {
        name: 'Völkerball',
        description: 'Gespielt wird in zwei gleich grossen, durch eine Mittellinie geteilten Spielfeldern. Jeweils eine Spielerin pro Mannschaft geht in den Himmel (hinter die Grundlinie des gegnerischen Feldes). Jede Mannschaft versucht aus ihrem Spielfeld heraus, gegnerische Spieler abzuschiessen. Wer abgetroffen worden ist, muss hinter das Spielfeld der gegnerischen Partei und kann von dort aus ebenfalls versuchen gegnerische Spieler "abzutreffen". Wer den Ball fangen kann, behält sein Leben. Die Partei, die zuerst keine Spielerinnen mehr im Feld hat, verliert.',
        href: 'https://www.sportunterricht.ch/lektion/spielen/spielen6.php',
        duration: 25
    },
    {
        name: 'Bauchvölkerball',
        description: 'Dieses Spiel ist eine interessante Variante der Grundform. Die getroffenen Spielerinnen können sich wieder ins Innenfeld retten, indem, sie sich im Aussenfeld auf den Bauch legen, aufstehen und unmittelbar durchs Feld rennen. Werden sie dabei von einer gegnerischen Spielerin berührt, müssen sie wieder ins Aussenfeld zurück, von wo aus sie den nächsten Versuch starten können.',
        href: 'https://www.sportunterricht.ch/lektion/spielen/spielen6.php',
        duration: 25
    },
    {
        name: 'Kegelvölkerball',
        description: 'Wie Grundform. Jede Spielerin (ausser Spielerinnen im Himmel) müssen einen Kegel, den sie irgendwo im Innenfeld aufgestellt haben beschützen. Kann ein Kegel umgeschossen werden (oder er fällt durch Berührung der Beschützerin um), muss die Beschützerin in den Himmel. Wer im Himmel ist und einen Kegel abschiessen kann, darf wieder zurück ins Innenfeld. Diese Spielform ist nur interessant, wenn mit zwei Bällen gespielt wird!',
        href: 'https://www.sportunterricht.ch/lektion/spielen/spielen6.php',
        duration: 25
    },
    {
        name: 'Königsvölkerball',
        description: 'Jedes Team bestimmt im Geheimen einen König und einen Hofnarren. Nur der Spielleiter wird über die Wahl informiert. Wird während des Spiels der König getroffen, ist das Spiel zu Ende. Der Hofnarr kann nicht abgeworfen werden. Er beschützt (Schutzschild) die anderen Mitspieler und den König.',
        href: 'https://www.sportunterricht.ch/lektion/spielen/spielen6.php',
        duration: 25
    },
    {
        name: 'Zahlenvölkerball',
        description: 'Jedes Team nummeriert seine Spieler im Geheimen durch. Der Gegner muss zuerst die Nummer 1 abschiessen dann die Nummer 2 etc. Spieler mit anderen Nummern können nicht abgeworfen werden. Nummer 1 darf zu Beginn nicht im Himmel sein!',
        href: 'https://www.sportunterricht.ch/lektion/spielen/spielen6.php',
        duration: 30
    },
    {
        name: 'Burgvölkerball',
        description: 'Beim Burgenvölkerball darf jedes Team eine Burg mit diversen Geräten (Matten, Kasten, etc.) bauen, welche als Deckung dient. Beide Teams erhalten die gleiche Anzahl Bälle. Die Spieler der Teams sind durch unterschiedliche Spielbänder gekennzeichnet. Ziel ist es, die gegnerische Burg zu erobern und die gegnerischen Spieler zu treffen. Dabei darf man sich frei in der Halle bewegen. Getroffene Spieler müssen das Feld verlassen. Das Spiel ist zu Ende, wenn ein Team keine Spieler mehr hat.',
        href: 'https://www.sportunterricht.ch/lektion/spielen/spielen6.php',
        duration: 40
    },
    {
        name: 'Pantherball',
        description: 'Ziel des Spiel ist es, alle gegnerischen Spieler mit einem Ball abzuschiessen. Die Mittellinie trennt die gegnerischen Mannschaften. Es ist aber erlaubt, über die Matten die Mittellinie zu überqueren und auf der Matte das gegnerische Feld zu betreten (Die Schaumstoffmatte darf aber nicht verlassen werden). Ein Spieler, welcher getroffen wird, muss das Spielfeld verlassen. Er darf wieder zurück ins Spielfeld, wenn ein Mitspieler von der Mitte der Matten (Berg) den Ball auf das gegnerische Basketballbrett spielt (siehe Abbildung). Falls dies zu schwierig ist, kann man den Wurf auf das Brett auch weiter vorne zulassen. Erzielt jemand einen Korb, so dürfen alle Spieler zurück ins Spielfeld.',
        href: 'https://www.sportunterricht.ch/lektion/spielen/spielen35.php',
        duration: 20
    },
    {
        name: 'Schnappball',
        description: 'Zwei Mannschaften (max. 5:5) spielen in einem begrenzten Raum (halbe Halle) gegeneinander. Jede Mannschaft versucht den Ball mittels Zuspiel möglichst lange unter eigener Kontrolle zu halten. Die gegnerische Mannschaft versucht den Ball abzufangen. Gelingt dies wechseln die Aufgaben. Nach zehn erfolgten Zuspielen erhält die Mannschaft einen Punkt. Pro Team wird ein Schüler bestimmt, welcher die Zuspiele seines Teams laut zählt.',
        href: 'https://www.sportunterricht.ch/lektion/spielen/spielen41.php',
        duration: 15
    },
]

export { main_training }; 