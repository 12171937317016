import React, { Component }from 'react';

import TrainUnitList from './components/trainUnitList/trainUnitList';
import SaveButton from './components/saveButton/saveButton';
import { warm_up } from './train_units/warm_up';
import { main_training } from './train_units/main_training';
import { end_games } from './train_units/end_games';
import './App.css';


class App extends Component {

  render() {
    return (
      <div className="App">
        <header className="App-header">
          <TrainUnitList maxDuration='10' title='Aufwärmen' trainUnits={warm_up} cookieName='warm_up' />
          <TrainUnitList maxDuration='65' title='Training' trainUnits={main_training} cookieName='training'  />
          {/*<TrainUnitList maxDuration='15' title='Abschlussspiel' trainUnits={end_games} cookieName='end_game'  />*/}
        </header>
        {/*<footer>
          <SaveButton cookieNames={['warm_up', 'main_training', 'end_game']} />
        </footer>*/}
      </div>
    );
  }
}

export default App;
