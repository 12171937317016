const warm_up = [
    {
        name: 'Linienlauf',
        description: 'Alle SchülerInnen stellen sich auf der Volleyballgrundlinie auf (bei grossen Klassen zwei/drei Gruppen machen). Die SchülerInnen laufen zuerst zur drei Meter Linie und zurück (Distanz gleich 12 Meter). Nun laufen sie zur Mittellinie (18 Meter) und wieder zurück zur Aufschlaglinie. Jetzt müssen sie zur "gegnerischen" drei Meter Linie laufen und wieder zurück zur Aufschlaglinie (24 Meter). Zuletzt laufen sie zur gegnerischen Aufschlaglinie durchs Ziel (18 Meter). Insgesamt beträgt die Distanz 72 Meter. Bis auf die Ziellinie müssen alle Linien mit der Hand berührt werden.',
        href: 'https://www.sportunterricht.ch/lektion/LSW/lsw8.php',
        duration: 8
    },
    {
        name: '15, 14',
        description: '15, 14 ist ein Versteckspiel. In der ganzen Halle werden Geräte aufgestellt, hinter denen man sich verstecken kann. Das Spiel beginnt in der Mitte der Halle. Alle Schüler stellen sich um den „Suchenden“ Schüler auf und müssen diesen berühren. Sobald der „Suchende“ von 15 hinunter zu zählen beginnt, verstecken sich alle irgendwo in der Halle. Der „Suchende“ muss während des Zählen die Augen schliessen. Bei 0 angelangt darf er die Augen öffnen. Jetzt darf er maximal drei Schritte machen und die übrigen Schüler suchen. Kann er einen entdecken, muss er dessen Namen rufen und sagen wo er sich versteckt (Roger hinter der blauen Matte). Dieser scheidet aus und muss sich an den Hallenrand begeben. Kann der „Suchende“ niemanden mehr entdecken, beginnt er wieder blind von 14 bis 0 laut zu zählen. Jetzt müssen alle aus ihrem Versteck hervorkommen, den Suchenden kurz berühren und sich dann sofort wieder verstecken. Nun beginnt wieder die Suche des Suchenden (max. drei Schritte). Beim nächsten Durchgang zählt er von 13 bis 0, usw. Die Zählzeiten werden immer kürzer, womit die Mitspieler immer hektischer zwischen ihren Verstecken und dem „Sucher“ hin und her laufen müssen. Das Spiel ist beendet, wenn alle Spieler gefunden wurden. Der letzte Spieler wird zum „Suchenden“.',
        href: 'https://www.sportunterricht.ch/lektion/spielen/spielen112.php',
        duration: 6
    },
    {
        name: 'Amerikanisches Sitzball (Jogging)',
        description: 'Amerikanisches Sitzball ist eine Variante des Sitzballspiels. Es spielen alle gegen alle. Kann ein Spieler einen anderen abschiessen, wird der getroffene Spieler zum Geist. Der Geist muss nun solange um das Spielfeld herumlaufen bis sein Mörder getroffen wird. Nun ist der Geist frei und darf wieder im Feld weiterspielen.',
        href: 'https://www.sportunterricht.ch/lektion/spielen/spielen46.php',
        duration: 10
    },
    {
        name: '2er Sitzball',
        description: 'Je zwei Schüler bilden ein Team. Sie dürfen einander den Ball zuspielen. Ziel ist es möglichst viele andere Spieler abzuschiessen. Wer abgeworfen wird, muss sich hinsetzen. Man kann erlöst werden, indem man einen Ball vom Partner zugespielt bekommt oder einen rollenden Ball erwischt. Werden beide Schüler abgeworfen, so müssen sie gemeinsam eine Zusatzaufgabe (zwei Hallenrunden joggen oder eine andere Aufgabe) lösen, bevor sie wieder weiterspielen dürfen.',
        href: 'https://www.sportunterricht.ch/lektion/spielen/spielen109.php',
        duration: 10
    },
    {
        name: '(Amerikanisches) Gruppensitzball',
        description: 'Gruppensitzball ist eine Variante des "normalen Sitzballs". Vier Mannschaften spielen sich den Ball zu und versuchen Spieler der anderen Mannschaften abzuschiessen. Getroffene Spieler müssen das Spielfeld verlassen und dürfen das Spielfeld erst wieder betreten, wenn derjenige Spieler getroffen wird, von welchem er selber getroffen wurde.',
        href: 'https://www.sportunterricht.ch/lektion/spielen/spielen68.php',
        duration: 10
    },
    {
        name: 'Tupfball',
        description: 'Team A mit Ball versucht möglichst viele gegnerische Spieler mit einem Ball zu treffen. Ein Treffer zählt nur, wenn der gegnerische Spieler mit dem Ball berührt wird (kein Schuss!). Nach 2-4 Minuten wechselt der Ballbesitz. Wer erzielt mehr Treffer? Je nach Klassengrösse muss der Raum verkleinert werden (halbe Halle).',
        href: 'https://www.sportunterricht.ch/lektion/spielen/spielen42.php',
        duration: 8
    },
    {
        name: 'Wandball',
        description: 'Mannschaft A versucht beim Gegner ein Tor zu schiessen. Das Tor entspricht der ganzen Hallenstirnseite. Ein Tor zählt nur, wenn der Ball direkt von der Wand zurück zu einem Mitspieler fliegt und dieser den Ball fangen kann. Bälle werden wie beim Handball einander zugepasst. Dribbeln und Laufen mit dem Ball ist nicht erlaubt. Berührt der Ball die Hallenlängswände erhält der Gegner den Ball.',
        href: 'https://www.sportunterricht.ch/lektion/spielen/spielen9.php',
        duration: 15
    },
    {
        name: 'Farbenspiel',
        description: 'Alle Schüler bewegen sich frei in einer Hallenhälfte. Der Lehrer/Leiter ruft laut eine bestimmte Farbe oder ein bestimmtes Material. Die Schüler müssen nun möglichst schnell ausserhalb des vorgegebenen Raumes diese Farbe oder das Material berühren und sofort wieder zurück ins Feld laufen. Wer ist zuerst wieder zurück?',
        href: 'https://www.sportunterricht.ch/lektion/spielen/spielen36.php',
        duration: 7
    },
]

export { warm_up }; 